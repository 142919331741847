
import React, { useState, useEffect } from 'react'
import { localStorageKeys, LocalStorageService } from '../../application/services'
import { PopupCartona } from '../../components/ui/Modal'
import { cartonaPromo, etisalatHost, hideBanner, insuranceTypeEnum } from '../../config/enums'
import { Header, Footer } from './components'
import { SmartAppBanner } from '../../components'
import { useLocation } from 'react-router-dom';

const Layout = (props) => {
    const [visible, setVisible] = useState(false)
    const [packagesPage, setPackagesPage] = useState(false)
    const location = useLocation();
    useEffect(() => {
        window.location.search.includes(cartonaPromo) && setVisible(true)
    }, []);
    useEffect(() => {
        setPackagesPage(window.location.pathname.includes("loading-packages"))
    }, [location]);
    return (
        <div className="main-layout">
            {!etisalatHost && !window.location.href.includes(insuranceTypeEnum.App) && !window.location.href.includes(hideBanner) ? <SmartAppBanner /> : null}
            {LocalStorageService.get(localStorageKeys.app) ||
                window.location.href.includes(insuranceTypeEnum.App) ||
                window.location.href.includes(insuranceTypeEnum.RemoveLayout) ||
                etisalatHost ? null : <Header packagesPage={packagesPage} />}
            {props.children}
            {LocalStorageService.get(localStorageKeys.app) ||
                window.location.href.includes(insuranceTypeEnum.App) ||
                window.location.href.includes(insuranceTypeEnum.RemoveLayout) ||
                etisalatHost ? <div className='footer-app' /> : <Footer />}
            <PopupCartona show={visible} title='اهلاً بيك في برامج تأمين شركة أمنلي'
                description='لأنك عميل مميز لشركة كرتونة هنوفر لك افضل برامج التأمين الطبي بتخفيض 10% على جميع اسعارنا'
                onClick={() => setVisible(false)} btnText='تابع وادخل بياناتك'
                handleClose={() => setVisible(false)}
            />
        </div>
    )
}
export default Layout
